<template>
    <vs-card class="text-center mp-5" style="width: 70%;">
          <imageLazy  :src="baseURL+doctor.ProfileImagePath"  placeHolderType="doctor"
          :imageStyle="{' float': 'left','width': windowWidth>500?   '20vh':'10vh','height':  windowWidth>500?   '20vh':'10vh', 'border-radius': windowWidth>500?   '10vh':'5vh'}" />
          <h3 class="darkBlue ">{{doctor.Name}}</h3>
    </vs-card>
</template>
<script>
import imageLazy from '../image_lazy.vue'
import { domain } from "@/gloabelConstant.js";

export default {
  components: { imageLazy },
  props:{
      doctor:{
          type:Object,
          required:true
      }
  },
  data()
  {
      return{
          baseURL: domain,
      }
  }
,
computed:{
   windowWidth() {
      return this.$store.state.windowWidth;
    },
}

}
</script>
<style>

* { box-sizing: border-box; }

.container {
  display: flex;
  flex-flow: row wrap;
}

.card-wrap {
  flex: 0 0 33.333%;
  display: flex;
  padding: 10px; /* gutter width */
}

.card {
  box-shadow: 0 0 4px rgba(0,0,0,0.4);
  flex: 0 0 100%;
}
</style>
