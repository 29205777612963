<template>
  <div id="homev2" class="mb-15">
    <vs-row vs-w="12" v-if="windowWidth >= 1200">
      <vs-col vs-type="flex" vs-sm="0" :vs-w="1.2">
        <side-bar style="height: 50vh" />
      </vs-col>
    </vs-row>
    <!--
    <img
      v-if="$vs.rtl"
      id="sliderImage"
      src="@/assets/images/home-assets/SliderAr.jpg"
      :class="windowWidth >= 1200 ? 'SliderStyle' : 'SliderStyleMobile'"
    /> -->

    <!-- <img
      v-else
      id="sliderImage"
      src="@/assets/images/home-assets/imgslid.png"
      :class="windowWidth >= 1200 ? 'sliderEn' : 'SliderStyleMobile'"
    /> -->

    <!-- Slider-->
    <div vs-w="12" class="mainSlider">
      <div   >
  <img
     vs-w="6"
        id="sliderImage"
        src="@/assets/images/home-assets/imgslid.png"
        :class="windowWidth >= 1200 ? 'sliderEn' : 'SliderStyleMobileEn'"
        style="border-style: solid; border-color: #92a8d1"
      />
      </div>

 <!-- complete Slider text-->
    <div   >
 <vs-card
  vs-w="6"
        flex
        style="background-color: #FAFAFAFD;  border-radius:10px; border-style: solid; border-color: #92a8d1"
        :class="windowWidth >= 1200 ? 'CardEn' : 'SliderStyleMobileEn'"
      >
        <h2
          flex
          style="
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            color: rgb(0, 194, 38);
            margin-top: 1rem;
            padding-top: 30px;
          "
        >
          {{ $t("silderheader") }}
        </h2>

         <h2
          flex
          style="
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            color: rgb(0, 194, 38);
            margin-top: 10px;
          "
        >
          {{ $t("silderheader1") }}
        </h2>
        <h3
        flex
          style="
            text-align: center;
            font-size: 1.2rem;
            padding-top: 3%;
            color: #047;
          "
        >
          {{ $t("sliderheaderdesc") }}
        </h3>
        <h3
        flex
          style="
            text-align: center;
            font-size: 1.2rem;
            color: #047;
          "
        >
          {{ $t("sliderheaderdesc1") }}
        </h3>
        <div style="padding-top: 15px" flex>
        <vs-row
        flex
          vs-w="12"
          v-if="windowWidth >= 1200"
          vs-justify="center"
          vs-align="center"
        >
          <vs-col  flex vs-w="12" v-if="windowWidth > 500" vs-sm="1">
            <div  flex>

            <span style="color: #047;font-size: 0.8rem;white-space: nowrap;justify-content: space-between;" >
              <check-circle-icon size="1.5x" style="color:rgb(0, 194, 38);justify-content: space-between;"></check-circle-icon>
             {{ $t("sent1") }}
            </span>


          <span style="color: #047; font-size: 0.8rem; padding:60px;" >
             <check-circle-icon size="1.5x" style="color:rgb(0, 194, 38);" ></check-circle-icon>
              {{ $t("sent2") }}
            </span>
            </div>
          </vs-col>
        </vs-row>

        <vs-row
        flex
          vs-w="12"
          v-if="windowWidth >= 1200"
          vs-justify="center"
          vs-align="center"
        >
          <!-- <vs-col>
            <span style="color: #047; font-size: 0.9rem; display: block">
              Book an in-person ot online docotor visit minutes.
            </span>
          </vs-col> -->
          <vs-col style=";margin-top:0.2cm;"  flex>

            <span style="color: #047; font-size: 0.8rem;">
               <check-circle-icon size="1.5x" style="color:rgb(0, 194, 38);padding-top:0px"></check-circle-icon>
              {{ $t("sent3") }}
            </span>

            <span style="color: #047; font-size: 0.8rem; padding:22px " v-if="!$vs.rtl">
               <check-circle-icon size="1.5x" style="color:rgb(0, 194, 38);"></check-circle-icon>
              {{ $t("sent4") }}
            </span>

                <span style="color: #047; font-size: 0.8rem; padding:80px " v-if="$vs.rtl">
               <check-circle-icon size="1.5x" style="color:rgb(0, 194, 38);"></check-circle-icon>
             {{ $t("sent4") }}
            </span>
          </vs-col>
        </vs-row>
        </div>
      </vs-card>
    </div>

    </div>
 <!-- Search -->
    <div
      flex
      id="searchDev"
      :class="windowWidth >= 1200 ? 'search' : 'searchMobile'"
      :style="{ width: '100%' }"
    >
      <div flex
        class="whiteBack searchCard"
        :class="[windowWidth > 1200 ? 'searchCard' : 'searchCardMobil']"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="2"
            v-if="windowWidth > 500"
            vs-sm="0"
          >
            <feather-icon
              icon="UsersIcon"
              class="cursor-pointer"
            ></feather-icon>
            <h4 class="fontMfontBookedium text-bold ml-2 mr-2">
              {{ $t("FindDoctor") }}
            </h4>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
            vs-sm="0"
          >
            <div class="divider"></div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            vs-sm="12"
            vs-xs="12"
          >
            <feather-icon
              icon="MapPinIcon"
              class="cursor-pointer m-2"
            ></feather-icon>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              style="width: 80%"
              class="w-full"
              id="destinationMobile"
              label="Name"
              v-model="search.CountryID"
              :options="countries"
              @input="getMedicalPackageCategory"
              :reduce="(ID) => ID.ID"
              :placeholder="$t('Destination')"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
            v-if="windowWidth >= 900"
          >
            <div class="divider"></div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-sm="12"
            v-if="windowWidth < 900"
          >
            <div class="Hdivider"></div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            vs-sm="12"
          >
            <feather-icon
              icon="CommandIcon"
              class="cursor-pointer m-2"
            ></feather-icon>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              style="width: 100%"
              id="specialyMobile"
              class="w-full"
              label="Name"
              v-model="search.SpecialtyID"
              :options="specialties"
              :placeholder="$t('Specialty')"
              :reduce="(ID) => ID.ID"
            />
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-align="center"
            v-if="windowWidth >= 900"
            vs-w="2"
            vs-sm="0"
          >
            <div
              class="greenBackColor searchButton w-full"
              @click="
                $router
                  .push(
                    'DoctorSearch/' +
                      search.CountryID +
                      '/' +
                      search.SpecialtyID
                  )
                  .catch(() => {})
              "
            >
              <vs-row
                vs-w="12"
                class="mt-5"
                vs-justify="center"
                vs-align="center"
              >
                <vs-col vs-justify="flex-end" vs-align="flex-start" vs-w="4">
                  <h6 class="whitecolor text-bold">
                    {{ $t("Search") }}
                  </h6>
                </vs-col>
                <vs-col vs-justify="flex-end" vs-align="center" vs-w="5">
                  <feather-icon
                    style="padding-top: 10px"
                    class="whitecolor ml-5 mr-5"
                    icon="SearchIcon"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
            v-if="windowWidth < 900"
            vs-sm="12"
          >
            <div
              class="greenBackColor w-full searchButtonMobil"
              @click="
                $router
                  .push(
                    'DoctorSearch/' +
                      search.CountryID +
                      '/' +
                      search.SpecialtyID
                  )
                  .catch(() => {})
              "
            >
              <vs-row
                vs-w="12"
                class="mt-2"
                vs-justify="center"
                vs-align="center"
              >
                <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">
                  <h4 class="whitecolor text-bold" style="text-align: end">
                    {{ $t("Search") }}
                  </h4>
                </vs-col>
                <vs-col vs-justify="flex-start" vs-align="center" vs-w="2">
                  <feather-icon
                    style="padding-top: 10px"
                    class="whitecolor"
                    icon="SearchIcon"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <!-- <div style="height:50vh" v-if="windowWidth < 1200">
     </div> -->


      <!-- TopDestination-->
    <div
      class="mt-5"
      :style="{
        'background-image': `url(${require('@/assets/images/home-assets/shape.png')})`,
        backgroundRepeat: 'round',
      }"
    >
      <div class="TopDestination">
        <vs-row class="mt-5" vs-w="12" vs-align="center" vs-justify="center">
          <vs-col vs-type="flex" vs-w="4" vs-sm="12">
            <custom-button
              id="hoverButton"
              @click="$router.push('/AppointmentSearch')"
              style="width: 100%"
              Type="border"
              Color="rgb(255, 255, 255)"
              :title="$t('Doctors')"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-w="4" vs-sm="12">
            <custom-button
              id="hoverButton"
              @click="$router.push('/AppointmentSearch')"
              style="width: 100%"
              class="m-2"
              Type="border"
              Color="rgb(255, 255, 255)"
              :title="$t('OnlineAppointment')"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-w="4" vs-sm="12">
            <custom-button
              id="hoverButton"
              @click="$router.push('/SurgeryAndTreatment')"
              style="width: 100%"
              Type="border"
              Color="rgb(255, 255, 255)"
              :title="$t('SurgeryAndTreatment')"
            />
          </vs-col>
        </vs-row>
        <h4
          class="text-center whitecolor fontBook pt-10"
          style="text-transform: uppercase"
        >
          {{ $t("Top") }} {{ $t("Destinations") }}
        </h4>
        <vs-row vs-w="12" vs-align="center" vs-justify="center">
          <vs-col
            v-for="item in topDestinationsPage"
            :key="item.ID"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
            vs-sm="2"
            class="mt-2"
          >
            <div @click="gotoRelatedPage(item)">
              <div>
                <div class="imageDiv">
                  <imageLazy
                    :imageStyle="{
                      height: '5vh',
                      width: '5vh',
                      'border-radius': '50px',
                      border: 'white 3px solid',
                      cursor: 'pointer',
                    }"
                    :src="baseURL + item.ImagePath"
                    placeHolderType="country"
                  />
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <!-- Checkuppackages-->
    <div class="mt-5">
      <vs-row vs-w="12" class="pb-10" v-if="windowWidth >= 500">
        <vs-col vs-type="flex" vs-sm="0" :vs-w="1.2">
          <side-bar
            style="font-color: red"
            :height="Math.ceil(medicalPackageCategories.length / 2) * 15"
            :title="$t('Checkuppackages')"
            title-color="red"
            :style="{
              height:
                Math.ceil(medicalPackageCategories.length / 2) * 15 + 5 + 'vw',
            }"
          />
        </vs-col>
        <vs-col vs-offset=".8" vs-type="flex" vs-sm="0" :vs-w="10">
          <div id="packages" style="width: 60vw; margin: auto" class="vx-row">
            <div
              style="height: 15vw"
              class="vx-col vx-col:w-1/2"
              v-for="(item, index) in medicalPackageCategories"
              :key="index"
            >
              <package-card
                :colorclass="item.BgColor"
                :item="item"
                :index="index"
                :iconImage="item.IconImagePath"
              />
            </div>
          </div>
        </vs-col>
        <vs-col vs-offset=".8" vs-type="flex" vs-sm="0" :vs-w="1.2"> </vs-col>
      </vs-row>
      <vs-row class="" vs-w="12" vs-justify="center" v-if="windowWidth < 500">
        <vs-col
          class="mt-2"
          vs-offset="1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="1"
          vs-sm="12"
        >
          <h2 style="margin-right: 50px" class="primarycolor">
            {{ $t("Checkuppackages") }}
          </h2>
        </vs-col>
        <vs-col
          class="mt-2"
          v-for="item in medicalPackageCategories"
          :key="item.ID"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          vs-sm="12"
        >
          <package-card-one
            :colorclass="'primarycolor'"
            class="mt-5"
            :data="item"
          />
        </vs-col>
        <vs-col class="mt-2" vs-type="flex" vs-w="1" vs-sm="12"> </vs-col>
      </vs-row>
    </div>


<!-- whyus-->
    <div
      class="mt-5 pt-10 pb-10"
      :style="{
        'background-image': `url(${require('@/assets/images/home-assets/shape.png')})`,
        backgroundRepeat: 'round',
      }"
    >
      <vs-row :vs-w="12" vs-align="center">
        <vs-col
          vs-type="flex"
          vs-sm="0"
          :vs-w="1"
          vs-justify="center"
          vs-align="center"
        >
          <div :style="{ 'padding-top': '2vh' }">
            <h3
              style="
                transform: rotate(-90deg);
                color: white;
                text-transform: uppercase;
                width: 250px;
              "
            >
              {{ $t("advantageschoose") }}
              <div
                style="height: 2px; width: 40px; background: white; margin: 2px"
              ></div>
            </h3>
          </div>
        </vs-col>
        <vs-row vs-type="flex" vs-sm="12" vs-w="10" vs-justify="center">
          <vs-col
            vs-type="flex"
            vs-sm="10"
            vs-xs="10"
            vs-lg="3"
            vs-w="3"
            style="padding-right: 10px; padding-left: 10px"
          >
            <vs-card
              flex
              style="background-color: #fffafa; cursor: pointer"
              id="hoverButton"
            >
              <img
                src="@/assets/images/home-assets/immediate.png"
                style="
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  width: 20%;
                  padding-top: 2%;
                "
              />
              <h2
                flex
                style="
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  color: rgb(0, 194, 38);
                  margin-top: 10px;
                "
              >
                {{ $t("immediate") }}
              </h2>
              <h3
                style="
                  text-align: center;
                  font-size: 1.2rem;
                  padding-top: 1%;
                  color: black;
                "
              >
                {{ $t("immediatedesc") }}
              </h3>
            </vs-card>
          </vs-col>



          <vs-col
            vs-type="flex"
            vs-sm="10"
            vs-xs="10"
            vs-lg="3"
            vs-w="3"
            style="padding-right: 10px; padding-left: 10px"
          >
            <vs-card
              style="background-color:#FFFAFA;cursor: pointer;,"
              id="hoverButton"
            >
              <img
                src="@/assets/images/home-assets/avoid.png"
                style="
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  width: 20%;
                  padding-top: 2%;
                "
              />
              <h2
                flex
                style="
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  color: rgb(0, 194, 38);
                  margin-top: 10px;
                "
              >
                {{ $t("avoid") }}
              </h2>
              <h3
                style="
                  text-align: center;
                  padding-top: 1%;
                  font-size: 1.2rem;

                  color: black;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                {{ $t("avoiddesc") }}
              </h3>
            </vs-card>
          </vs-col>
          <vs-col
            vs-sm="10"
            vs-type="flex"
            vs-xs="10"
            vs-lg="3"
            vs-w="3"
            style="padding-right: 10px; padding-left: 10px"
          >
            <vs-card
              style="background-color: #fffafa; cursor: pointer"
              id="hoverButton"
            >
              <img
                src="@/assets/images/home-assets/consultants.png"
                style="
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  width: 20%;
                  padding-top: 2%;
                "
              />
              <h2
                flex
                style="
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  color: rgb(0, 194, 38);
                  margin-top: 10px;
                "
              >
                {{ $t("consultants") }}
              </h2>
              <h3
                style="
                  text-align: center;
                  padding-top: 1%;
                  font-size: 1.2rem;
                  color: black;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                {{ $t("consultantsdesc") }}
              </h3>
            </vs-card>
          </vs-col>
          <!-- </div> -->
        </vs-row>
      </vs-row>
    </div>

<!-- Top Doctor-->
    <div
      class="p-10"
      :style="{
        'background-image': `url(${require('@/assets/images/home-assets/headBg.png')})`,
        backgroundRepeat: 'round',
        backgroundSize: 'cover',
      }"
    >
      <vs-row vs-justify="center" vs-w="12">
        <vs-col vs-w="3" vs-offset="1" vs-sm="9">
          <p class="uppercase greenColor mt-10">{{ $t("TopDoctors") }}</p>
          <h1 class="uppercase darkBlue mt-2" style="font-weight: bold">
            {{ $t("MeetOur") }}
          </h1>
          <h1 class="uppercase darkBlue mt-2" style="font-weight: bold">
            {{ $t("Doctors") }}
          </h1>
          <p style="font-family: Sans-serif">{{ $t("accessOurDB") }}</p>
          <vs-row vs-w="12" class="mt-5 mb-5">
            <vs-col vs-w="6" vs-sm="9">
              <vs-button
                type="filled"
                @click="$router.push('/AppointmentSearch')"
                class="small"
                color="rgb(0, 193, 38)"
              >
                <span class="uppercase">
                  {{ $t("searchByDoctor") }}
                </span></vs-button
              >
            </vs-col>
            <vs-col vs-w="6" vs-sm="9">
              <vs-button
                type="filled"
                @click="$router.push('/AppointmentSearch')"
                class="small"
                color="rgb(255,255,255)"
              >
                <span class="darkBlue uppercase"
                  >{{ $t("searchBySpeciality") }}
                </span></vs-button
              >
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="3" vs-offset="1" vs-sm="9" vs-justify="center center">
          <div v-for="(item, index) in firstDotor" :key="index">
            <topdoctor :doctor="item" />
          </div>
        </vs-col>
        <vs-col vs-w="3"  vs-offset="0.9" vs-sm="9" vs-justify="center center">
          <div class="mt-12">
            <div v-for="(item, index) in secondDotor" :key="index">
              <topdoctor :doctor="item" />
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>


<!-- OurPartners-->
    <!-- <div
      class="mb-5 p-10"
      :style="{
        'background-image': `url(${require('@/assets/images/home-assets/shape.png')})`,
        backgroundRepeat: 'round',
      }"
    >
      <h2
      flex
        class="whitecolor text-center mb-6"
        style="font-weight: bolder; text-transform: uppercase"
      >
        {{ $t("OurPartners") }}
      </h2>
      <vs-row vs-justify="center" vs-align="center" :vs-w="12" vs-type="flex" flex>
        <vs-col vs-type="flex" vs-sm="1" :vs-w="1" v-if="!$vs.rtl" flex>
          <img
          flex
            src="@/assets/images/home-assets/arrowLeft.png"
            @click="PartnerNavigation(-1)"
            class="handclick"
            :style="{ width: windowWidth >= 500 ? '' : '100%' }"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-sm="1" :vs-w="1" v-if="$vs.rtl" flex>
          <img
          flex
            src="@/assets/images/home-assets/arrowRight.png"
            @click="PartnerNavigation(-1)"
            class="handclick"
            :style="{ width: windowWidth >= 500 ? '' : '100%' }"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-sm="9" :vs-w="9" flex>
          <div
          flex
            style="text-align: center; margin-left: auto; margin-right: auto"
            v-if="currentPartner.ID"
            @click="$router.push('/HospitalProfile/' + currentPartner.ID)"
          >
            <imageLazy
            flex
              :key="currentPartner.ID"
              :imageStyle="{
                height: windowWidth >= 500 ? '30vh' : '100%',
                'border-radius': '15px',
                cursor: 'pointer',
                'max-width': '30vh',
                width: windowWidth >= 500 ? '30vh' : '100%',
              }"
              class="mb-33 px-2"
              :src="baseURL + currentPartner.ImagePath"
              placeHolderType="hospital"
            />
            <h5 flex style="color: white" class="px-8">
              {{ currentPartner.Name }}
            </h5>
          </div>
          <div
          flex
            style="text-align: center; margin: auto"
            v-if="secondPartner.ID"
            @click="$router.push('/HospitalProfile/' + secondPartner.ID)"
          >
            <imageLazy
            flex
              :key="secondPartner.ID"
            :style="{ width: windowWidth >= 500 ? '' : '100%' }"

              :imageStyle="{
                height: windowWidth >= 500 ? '30vh' : '100%',
                'border-radius': '15px',
                cursor: 'pointer',
                'max-width': '30vh',
                width: windowWidth >= 500 ? '30vh' : '100%',
              }"
              class="mb-33 px-2"
              :src="baseURL + secondPartner.ImagePath"
              placeHolderType="hospital"
            />
            <h5 flex style="color: white" class="px-8">
              {{ secondPartner.Name }}
            </h5>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-sm="1" :vs-w="1" v-if="$vs.rtl" flex>
          <img
            src="@/assets/images/home-assets/arrowLeft.png"
            @click="PartnerNavigation(-1)"
            class="handclick"
            :style="{ width: windowWidth >= 500 ? '' : '100%' }"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-sm="1" :vs-w="1" v-if="!$vs.rtl" flex>
          <img
          flex
            src="@/assets/images/home-assets/arrowRight.png"
            @click="PartnerNavigation(1)"
            class="handclick"
            :style="{ width: windowWidth >= 500 ? '' : '100%' }"
          />
        </vs-col>
      </vs-row>
    </div> -->

    <vs-popup
      title=""
      :active.sync="showTabsPopup"
      id="popyy"
      v-if="showTabsPopup"
    >
      <div class="justify-center items-center customBG">
        <div style="border-radius: 15px" class="">
          <div class="p-5">
            <div class="MyPopClose">
              <feather-icon
                class="mt-5"
                style="color: white"
                icon="XIcon"
                svgClasses="w-10 h-10 stroke-current"
                @click.stop="showTabsPopup = false"
              />
            </div>
            <h3
              vs-type="flex"
              vs-justify="center"
              style="
                color: white;
                margin-left: 40%;
                margin-right: 40%;
                margin-bottom: 5%;
              "
            >
              {{ $t("Pleaseselectoneofthose") }}
            </h3>
            <vs-row class="mt-1" vs-w="12" vs-type="flex" vs-justify="center">
              <vs-col
                v-for="item in services"
                :key="item.id"
                class="mt-1"
                vs-type="flex"
                vs-justify="space-between"
                vs-align="center"
                vs-w="4"
                vs-sm="12"
              >
                <!-- -->
                <home-card
                  @CardClick="NavigateToSearch"
                  class="w-full"
                  :data="item"
                  :services="services"
                  :search="search"
                ></home-card>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import step from "../components/home/step.vue";

import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";
// import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import partenersCard from "../components/home/partenersCard.vue";
import homeCard from "../components/home/homeCard.vue";
import VueHorizontal from "vue-horizontal";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import TermsAndCondetionsPopup from "./TermsAndCondetionsPopup";
import FAQPopup from "./FAQPopup";
import PatientWaiverPopup from "./PatientWaiverPopup";
import imageLazy from "../components/image_lazy.vue";
import packageCard from "@/components/home/packageCardV2.vue";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import packageCardOne from "@/components/home/packageCard.vue";

import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,

} from "vue-flux";
import FeatherIcon from "../components/FeatherIcon.vue";
import SideBar from "../components/SideBar.vue";
import VxCard from "../components/vx-card/VxCard.vue";
import topdoctor from "@/components/home/topDoctor.vue";
import VxCard1 from "../components/vx-card/VxCard.vue";
import { CheckCircleIcon } from 'vue-feather-icons';


export default {
  components: {
    packageCardOne,
    topdoctor,
    imageLazy,
    homeCard,
    packageCard,
    partenersCard,
    step,
    CheckCircleIcon,
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
    VueHorizontal,
    FeatherIcon,
    TermsAndCondetionsPopup,
    FAQPopup,
    PatientWaiverPopup,
    SideBar,
    VxCard,
    VxCard1,
  },
  data() {
    return {

      currentPartner: {},
      secondPartner: {},
      paymentShow: true,
      // showTermsAndCondetionsPopup:false,
      showFAQPopup: false,
      showPatientWaiverPopup: false,
      showTabsPopup: false,
      items: [...Array(20).keys()].map((i) => {
        return { title: `Item ${i}`, content: `🚀 Content ${i}` };
      }),
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 4 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          padding: 24,
        },
        position: {
          start: 2,
        },
        autoplay: { play: true, repeat: true, speed: 2500 },
      },

      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 6,
      search: {},
      hospitalImgList: [],
      services: [
        {
          id: 1,
          imagesrc: "doctors.png",
          title: this.$i18n.t("Doctors"),
          coming: false,
          PageName: "DoctorSearch",
          selected: false,
        },

        {
          id: 2,
          imagesrc: "onlineAppointments.png",
          title: this.$i18n.t("OnlineAppointment"),
          coming: false,
          PageName: "AppointmentSearch",
          selected: false,
        },
        {
          id: 3,
          imagesrc: "surgeries.png",
          title: this.$i18n.t("SurgeryAndTreatment"),
          coming: false,
          PageName: "SurgeryAndTreatment",
          selected: false,
        },
      ],
      steps: [
        {
          id: 1,
          imagesrc: "clock.png",
          background: "#004477",
          discription: this.$i18n.t("clockDescribtion"),
        },
        {
          id: 2,
          imagesrc: "dollar.png",
          background: "#76A224",
          discription: this.$i18n.t("dollarDescribtion"),
        },
        {
          id: 3,
          imagesrc: "surface.png",
          background: "#004477",
          discription: this.$i18n.t("surfaceDescribtion"),
        },
      ],
      vfOptions: {
        autoplay: true,
      },
      vfImages: [
        require("@/assets/images/slider/01.png"),
        require("@/assets/images/slider/02.png"),
        require("@/assets/images/slider/03.png"),
        require("@/assets/images/slider/04.png"),
      ],
      vfTransitions: ["fade", "cube", "book", "wave"],
      vfCaptions: [
        "Caption for image 1",
        "Caption for image 2",
        "Caption for image 3",
      ],
    };
  },
  computed: {
    firstDotor() {
      debugger;
      return this.doctors.slice(0, 2);
    },
    secondDotor() {
      debugger;
      return this.doctors.slice(2, 4);
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },

    windowWidth() {
      return this.$store.state.windowWidth;
    },
    specialties() {
      return this.$store.state.SpecialtyList.specialties;
    },
    medicalPackageCategories() {
      debugger;
      return this.$store.state.MedicalPackageCategoryList
        .MedicalPackageCategorys;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },

    hospitals() {
      if (this.$store.state.HospitalList.hospitals)
        return this.$store.state.HospitalList.hospitals;
      else
        return [...Array(20).keys()].map((i) => {
          return { ImagePath: `Item ${i}`, Name: `🚀 Content ${i}`, ID: 1 };
        });
    },
    topDestinations() {
      return this.$store.state.CountryList.topDestinations;
    },
  },
  created() {
    debugger;
    if (!moduleMedicalPackageCategory.isRegistered) {
      debugger;
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      debugger;
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    if (
      !this.$store.state.DoctorList.doctors ||
      this.$store.state.DoctorList.doctors.length == 0
    )
      this.$store.dispatch("DoctorList/SimpleSearchDoctors",{});

    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    // this.$store.dispatch(
    //   "MedicalPackageCategoryList/GetAllMedicalPackageCategorys"
    // );
this.getMedicalPackageCategory();
    if (
      !this.$store.state.SpecialtyList.specialties ||
      this.$store.state.SpecialtyList.specialties.length == 0
    )
      this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    if (
      !this.$store.state.CountryList.topDestinations ||
      this.$store.state.CountryList.topDestinations.length == 0
    )
      this.$store.dispatch("CountryList/GetTopDestinations");

    if (
      !this.$store.state.CountryList.countries ||
      this.$store.state.CountryList.countries.length == 0
    ) {
      this.$store.dispatch("CountryList/GetAllDoctorCountries");
    }

    if (
      !this.$store.state.HospitalList.hospitals ||
      this.$store.state.HospitalList.hospitals.length == 0
    )
      this.$store.dispatch("HospitalList/GetAllHospitals").then((res) => {
        debugger;
        if (res.data.Data && res.data.Data.length > 0) {
          this.currentPartner = res.data.Data[0];
          this.secondPartner = res.data.Data[1];
          this.currentPartner.currentIndex = 0;
          this.secondPartner.secondIndex = 1;
        }
      });
    else {
      this.currentPartner = this.hospitals[0];
      this.currentPartner.currentIndex = 0;
    }

    //this.getHospitalsPartener();
  },
  methods: {
    getMedicalPackageCategory(){
      this.$store
      .dispatch("MedicalPackageCategoryList/SearchMedicalPackageCategories" , this.search)
    },
    PartnerNavigation(index) {
      debugger;
      var secondIndex = this.secondPartner.secondIndex;
      var currentIndex = this.currentPartner.currentIndex;
      currentIndex += index;
      secondIndex += index;
      if (currentIndex >= this.hospitals.length) {
        currentIndex = 0;
        secondIndex = 0;
      } else if (currentIndex < 0) {
        currentIndex = this.hospitals.length - 1;
        secondIndex = this.hospitals.length - 2;
      }
      this.currentPartner = this.hospitals[currentIndex];
      this.currentPartner.currentIndex = currentIndex;
      this.secondPartner = this.hospitals[secondIndex];
      this.secondPartner.secondIndex = secondIndex;
    },
    PayNow() {
      this.paymentShow = false;
      document.getElementById("paymentForm").submit();
    },

    NavigateToSearch(item) {
      debugger;
      this.services.forEach((element) => {
        element.selected = false;
      });
      debugger;
      item.selected = true;

      this.$router.push({
        name: item.PageName,
        params: {
          CountryID: this.search.CountryID,
          SpecialtyID: this.search.SpecialtyID,
        },
      });
      // this.$router.push({ name: this.data.PageName });
    },

    gotoRelatedPage(data) {
      debugger;
      if (data != null && data != undefined && data.ID > 0) {
        this.search.CountryID = data.ID;
      }
      if (this.services.findIndex((element) => element.selected) >= 0) {
        var data = this.services.find((x) => x.selected == true);
        this.$router.push({
          name: data.PageName,
          params: {
            CountryID: this.search.CountryID,
            SpecialtyID: this.search.SpecialtyID,
          },
        });
      } else {
        this.showTabsPopup = true;
      }
    },
    getHospitalsPartener() {},
  },
  beforeCreate() {
    if (this.$acl.check("doctor")) this.$router.push("/doctor/Home");
    else if (this.$acl.check("hospital")) this.$router.push("/hospital/Home");
  },
  mounted() {
    debugger;
    var sliderDev = document.getElementById("sliderImage");
    var searchDev = document.getElementById("searchDev");

    searchDev.style.top =
      sliderDev.clientHeight - (searchDev.clientHeight + 0) + "px";
  },
};
</script>

<style>
#homev2 .SliderStyleMobile {
  margin-top: 70px;
  width: 100%;
}
#homev2 .SliderStyle {
  position: absolute;
  top: 0px;
  z-index: 90;
  left: 89px;
  width: 90vw;
  height: 49vh;
  object-fit: cover;
}

.sliderEn {
  position: absolute;
  top: 0px;
  z-index: 90;
  /* left: 89px; */
  width: 50%;
  height: 49vh;
  object-fit: cover;
}
.mainSlider{
  position: absolute;
  top: 0px;
  z-index: 90;
  left: 0px;
  width: 100%;
  height: 49vh;
  object-fit: cover;
}
.SliderStyleMobileEn {
  margin-top: 70px;
  width: 100%;

}

.CardEn {
  position: absolute;
  top: 0px;
  z-index: 90;
  right: 0px;
  width: 50%;
  height: 49vh;
  object-fit: cover;
}

#homev2 .vs-button--text {
  text-transform: uppercase;
}
#homev2 .searchMobile {
  z-index: 90;
}
#homev2 .search {
 /* position: absolute;
*/
   margin-top: 9vh;
  z-index:90;
  position: absolute !important;

}
#hoverButton:hover {
  border: 3px solid #00c425;
  color: #00c425;

  /* background-color: #00c425; */
}
.v-hl-btn[data-v-4eb718e5] {
  position: absolute !important;
  top: 10px !important;
}
.v-hl-btn-next[data-v-4eb718e5] {
  right: 25px !important;
}
.v-hl-btn-prev[data-v-4eb718e5] {
  left: 25px !important;
}
#specialyMobile .vs__dropdown-toggle {
  border-radius: unset;
}
#destinationMobile .vs__dropdown-toggle {
  border-radius: 18px;
}
</style>
<style scoped>
#homev2 .socialImage {
  width: 50px;
  height: 50px;
}

#homev2 .imageDiv {
  display: block;
}

#homev2 .shadowDiv {
  position: relative;
  height: 115px;
  bottom: 120px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}

#homev2 .searchCard {
  width: 70%;
  margin: auto;
  height: flex;
  margin-top: 3%;
  border-radius: 18px;
}
#homev2 .searchCardMobil {
  margin: auto;
  height: flex;
  border-radius: 18px;
  margin-top: 150%;
}
#homev2 .searchButton {
  height: 100%;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}
#homev2 .searchButtonMobil {
  height: 100%;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}
#homev2 .Hdivider {
  background: #00000029;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}
#homev2 .divider {
  background: #00000029;
  width: 3px;
  height: 80px;
}
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

#home .vs-inputx {
  background-color: $primary;
}
#home .input-span-placeholder {
  color: white;
}
#homev2 .TopDestination {
  text-transform: uppercase;
  width: 70%;
  margin: auto;

  padding: 1vh;
}
</style>
<style>
#homev2 .vue-flux {
  height: 80vh;
}
#homev2 .vs__dropdown-toggle {
  border: 0px solid #454a62 !important;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#homev2 .v-select .vs__dropdown-toggle .vs__search {
  font-size: 15px;
}
#homev2 .vs__selected {
  font-size: 15px;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#homev2 .greenColor {
  color: #00c425 !important;
}
#homev2 #homeImage:hover {
  background-color: #00c425;
  width: 70%;
  height: 70%;
  border-style: solid;
  border-width: 5px;
  border-color: #00c425;
  transition: transform 0.2s;
  transform: scale(1.2);
}

#popyy .customBG {
  width: 100%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
#popyy .CardButton {
  widows: 100%;
  border-radius: 32px 32px 32px 32px;
  margin-left: 2px;
  margin-right: 2px;
}
/* .CardButton{
   background-color: #76A224!important;
} */
</style>
