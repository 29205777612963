<template>
  <div
    id="packageCard"
    class="text-center cursor-pointer"
    @click="$router.push('/HospitalProfile/'+data.ID)"
    v-if="!baseURL + data.ImagePath"

  >
   
       <imageLazy
                      :imageStyle="{ 'border-radius': '20px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + data.ImagePath"
                      placeHolderType="hospital"
                    />

    <h4 class="fontMedium" :class="colorclass" style="">
      {{ data.Name }}
    </h4>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components:{
    imageLazy
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    colorclass: {
      type: String,
      default: "whitecolor",
    },
  },
  data() {
    return {
      baseURL: domain,
    };
  },
  methods: {
   
  },
};
</script>
<style >
.contain {
  width: 200px;
  height: 300px;
}
.mainbox {
  position: absolute;
  height: 200px;
  max-width: 80%;
}
.box {
  width: 190px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  border-radius: 15px;
  /* for demo purpose  */
}
#packageCard .stack-top {
  z-index: 9;
  margin: 0px 5px 0px 5px;
  /* for demo purpose  */
}
</style>
